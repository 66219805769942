<template lang="">
  <div>
    <div class="text-right">
      <b-button
        variant="outline-primary"
        @click="isAddNewpeoplesidebarActive = true"
      >
        <span class="text-nowrap">Ajouter</span>
      </b-button>
    </div>
    <datatable
      class="mt-4"
      :data-of-table="getList"
      :table-columns="fields"
      :resource="`peoples`"
      :total="getTotal"
      :is-busy="getLoading"
      :query-search="`fullName`"
      :buttons="[{icon:'CheckIcon', name:'actif',variant:'flat-success'},{icon:'XIcon',name:'inactif',variant:'flat-danger'}]"
      @actif="valid"
      @inactif="refuse"
    />
    <BSidebar
      :is-add-new-user-sidebar-active.sync="isAddNewpeoplesidebarActive"
    >
      <template #header>
        {{ header }}
      </template>
      <template #body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- FirstName -->
            <validation-provider
              #default="{ errors }"
              name="firstName"
              rules="required"
            >
              <b-form-group
                label="Nom"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  v-model="user.firstName"
                  :state="errors.length > 0 ? false:null"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- LastName -->
            <validation-provider
              #default="{ errors }"
              name="lastName"
              rules="required"
            >
              <b-form-group
                label="Prénom"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  v-model="user.lastName"
                  :state="errors.length > 0 ? false:null"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- email -->
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  :state="errors.length > 0 ? false:null"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- phone -->
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required"
            >
              <b-form-group
                label="Numéro de téléphone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="user.phone"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Adresse -->
            <validation-provider
              #default="{ errors }"
              name="adresse"
              rules="required"
            >
              <b-form-group
                label="Adresse"
                label-for="adresse"
              >
                <b-form-textarea
                  id="adresse"
                  v-model="user.adresse"
                  :state="errors.length > 0 ? false:null"
                  rows="3"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- password -->
            <b-form-group
              v-if="update === false"
              label="Mot de passe"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="user.password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              v-if="update === false"
              label-for="reset-password-confirm"
              label="Repeter le mot de passe"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="user.confirmPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <span v-if="loading === false"> {{ update === true ? 'Update' : 'Soumettre' }} </span>
                <span v-else>
                  <b-spinner small />
                </span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Annuler
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </BSidebar>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormTextarea, BInputGroupAppend, BInputGroup, BFormGroup, BForm, BButton, BSpinner, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import datatable from '@/@core/components/datatable/datatable.vue'
import BSidebar from '@/@core/components/b-sidebar/BSidebar.vue'

export default {
  components: {
    datatable,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormTextarea,
    // BRow,
    // BCol,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'fullName', label: 'Nom & Prénom', sortable: true,
        },
        {
          key: 'phone', label: 'Phone', sortable: true,
        },
        {
          key: 'user.email', label: 'Email', sortable: true,
        },
        {
          key: 'adresse', label: 'Adresse', sortable: true,
        },
        'roles',
        {
          key: 'etat', label: 'statut', sortable: true,
        },
        'actions',
      ],
      isAddNewpeoplesidebarActive: false,
      statusFilter: '',
      roleFilter: '',
      user: {
        firstName: '',
        lastName: '',
        adresse: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      },
      items: [],
      password1FieldType: 'password',
      password2FieldType: 'password',
      required,
      email,
      loading: false,
      update: false,
      header: 'Ajouter un utilisateur',
      id: '',
      roleOptions: [
        { label: 'Admin', value: 'ROLE_ADMIN' },
        { label: 'Manager', value: 'ROLE_MANAGER' },
        { label: 'Utilisateur', value: 'ROLE_USER' },
      ],
      statusOptions: [
        { label: 'Actif', value: 'Actif' },
        { label: 'Inactif', value: 'Inactif' },
      ],
    }
  },
  computed: {
    getList() {
      return this.$store.getters['peoples/list']
    },
    getTotal() {
      return this.$store.getters['peoples/totalItems']
    },
    getLoading() {
      return this.$store.getters['peoples/loading']
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    if (this.$store.getters['peoples/list'].length === 0) {
      this.$store.dispatch('peoples/list', { params: null })
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.user = {
        firstName: '',
        lastName: '',
        email: '',
        adresse: '',
        phone: '',
        password: '',
        confirmPassword: '',
      }
    },
    hide() {
      this.user = {
        firstName: '',
        lastName: '',
        email: '',
        adresse: '',
        phone: '',
        password: '',
        confirmPassword: '',
      }
      this.isAddNewpeoplesidebarActive = false
    },
    filter(params) {
      const body = params
      this.$store.dispatch('peoples/list', { params: body })
    },
    valid(items) {
      this.$bvModal
        .msgBoxConfirm('Êtes-vous sur de vouloir activer cet utilisateur ?', {
          centered: true,
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-secondary',
        })
        .then(async value => {
          console.log(value)
          if (value) {
            await this.$store.dispatch('peoples/updateUser', {
              id: items.user.id,
              params: null,
              item: {
                isActive: true,
              },
            }).then(async () => {
              await this.$store.dispatch('peoples/list', { params: null })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Activation effectuée',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            }).catch(async () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Une erreur est survenu. Veuillez recommencer ou actualiser la page',
                  icon: 'ErrorIcon',
                  variant: 'danger',
                },
              })
              this.loading = false
            })
          }
        })
    },
    refuse(items) {
      this.$bvModal
        .msgBoxConfirm('Êtes-vous sur de vouloir desactiver cet utilisateur ?', {
          centered: true,
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-secondary',
          okVariant: 'outline-danger',
        })
        .then(async value => {
          console.log(value)
          if (value) {
            await this.$store.dispatch('peoples/updateUser', {
              id: items.user.id,
              params: null,
              item: {
                isActive: false,
              },
            }).then(async () => {
              await this.$store.dispatch('peoples/list', { params: null })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Desactivation effectuée',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            }).catch(async () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Une erreur est survenu. Veuillez recommencer ou actualiser la page',
                  icon: 'ErrorIcon',
                  variant: 'danger',
                },
              })
              this.loading = false
            })
          }
        })
    },
    edit(items) {
      this.firstName = items.firstName
      this.lastName = items.lastName
      this.user = {
        fullName: '',
        adresse: items.adresse,
        phone: items.phone,
        email: items.user.email,
      }
      this.id = items.id
      this.header = 'Update un utilisateur'
      this.isAddNewpeoplesidebarActive = true
    },
    async remove(items) {
      this.id = items.id
      this.modalShow = true
    },
    async deleteData() {
      try {
        // const { token } = JSON.parse(localStorage.getItem('user'))
        await this.$store.dispatch('peoples/delete', { id: this.id, params: null })
        // await this.$http.delete(`sites/${this.id}`, { headers: { Accept: 'application/json', Authorization: `Bearer ${token}` } })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression effectuée',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.modalShow = false
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.loading = true
          if (this.update === true) {
            await this.$store.dispatch('peoples/update', { id: this.id, params: null, item: this.user })
          } else {
            await this.$store.dispatch('peoples/user', {
              page: 1,
              item: {
                email: this.user.email,
                password: this.user.password,
                roles: ['ROLE_MANAGER'],
                etat: 'Actif',
              },
              params: null,
            }).then(async () => {
              this.user.user = this.$store.getters['peoples/user']['@id']
              await this.$store.dispatch('peoples/add', { page: 1, item: this.user, params: null })
            })
            // eslint-disable-next-line no-return-assign
              .then(() => this.loading = false)
            // eslint-disable-next-line no-return-assign
              .catch(() => this.loading = false)
          }
          this.loading = false
          this.user = {
            firstName: '',
            lastName: '',
            adresse: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
          }
          this.id = ''
          this.isAddNewpeoplesidebarActive = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation effectuée',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="">

</style>
